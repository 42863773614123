/* Header Styles */
@media (min-width: 0px) {
    header {
        border-bottom: 6px solid white;
        height: 162px;
        max-width: 770px;
        overflow: hidden;
        margin: 0;
        position: relative;

        .header-image {
            background-repeat: no-repeat;
            border-left: 6px solid white;
            float: right;
            height: 120px;
            width: 372px;
            position: relative;
            overflow: hidden;

            .box {
                float: left;
                border-right: 6px solid white;
                height: 120px;
                width: 124px;
            }
        }
    }

    .company-logo {
        position: absolute;
        display: none;
        z-index: 5;
       
        img {
            height: auto;
            width: auto;
        }
    }

    .company-name {
        line-height: 1;
        position: absolute;
        text-decoration: none;
        z-index: 5;
        
        &:hover {
            text-decoration: none;
        }
    }

    .company-motto {
        line-height: 1;
        position: absolute;
        z-index: 5;
    }
}