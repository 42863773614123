/* Footer Styles */

footer {
    font-size: 8pt;
    padding: 0 1em 1em 1em;

    a {
        text-decoration: underline;
        color: #0000EE;

        &:hover {
            color: #0000EE;
        }

        &:visited {
            color: #551AAB;
        }
    }
}