/* Primary navigation */
@media (min-width: 0px) {
    #xs-navbar-collapse {
        border-bottom: 6px solid white;
        padding: 0;
        margin: 0;
        display: block;
        border-radius: 0;
        white-space: nowrap;
        width: 100%;
        z-index: 999;


        .xs-primary-navigation {
            width: 100%;

            li {
                float: left;
                max-height: 30px;
                text-align: center;
                width: 16.66666667%;

                &.spacer {
                    display: block;
                    height: 30px;
                    width: 16.66666667%;
                    border: none;
                    box-shadow: none;
                }

                &:first-child {

                }

                &:last-child {
                    
                }

                a {
                    line-height: 16px;
                    padding: 7px;
                    overflow: hidden;
                }

                a:hover {
                    background-color: white;
                }
            }
        }
    } /* /#xs-navbar-collapse */

    .navbar {
        min-height: 19px;
    }
    .navbar-nav>li>a {
        padding: 3px 0; 
    }
}