/* Layout
/////////////////////// */
html {
    overflow-y: scroll;
}

body {
    background-position: center center;
    background-repeat: repeat;
    font-size: 8pt;
    font-family: Verdana, Arial;
    background-color: #333333;
    color: #000000;}


h1 {
    margin-top: 0;
}

.navbar {
    border: none;

    a {
        
    }

}

.navbar-nav {
    margin: 0;
}

.container {
    width: auto;

    &.xs-main-container {
        border-right: 6px solid white;
        border-left: 6px solid white;
        background-color: #fff;
        width: 770px;
        overflow: auto;
        display: table;
    }
}

sup {
    font-size: 0.5em;
}


/* Header Styles
/////////////////////// */
@import 'header';

/* Navbar (Primary nav) Styles
/////////////////////// */
@import 'navbar';


/* Main Content Styles
/////////////////////// */
@import 'main';


/* Sidebar Styles
/////////////////////// */
@import 'sidebar';

/* Footer Styles
/////////////////////// */
@import 'footer';
