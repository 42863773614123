/* Sidebar Styles */
@media (min-width: 0px) {
    .xs-sidebar {  
        height: auto;
        padding: 0;
        vertical-align: top;
        white-space: nowrap;
        display: table-cell;
        float: none;

        &.show {
            height: auto;
        }

    }


    .xs-profile-picture {
        display: none;

        padding: 0 19px 0 19px;


        img {
            height: auto;
            max-width: 100%;
            border: 1px solid black;
        }
    }


    #xs-sidebar {
        padding: 0;

        .xs-secondary-navigation {
            margin: 0;
             /* Specific width of sidebar */
            width: 159px;

            li {
                width: 100%;
            }
        }


        .xs-alt-navigation {
            margin: 1em 10px;
        }

        li {
            
            display: block;
            position: relative;
            width: 100%;



            &.spacer {
                background: transparent;
                height: 18px;
                border: none;
            }

            /* indented items */
            &.indent > a {
                padding-left: 16px;
            }

            /* has sub-nav child */
            &.has-children {
                /* right arrow indicator */
                svg.sub-indicator {
                    opacity: 1;
                    position: absolute;
                    top: 6px;
                    right: 3px;
                    z-index: 5;
                }
            }

            /* 'fly-out' sub-navigation */
            .sub-nav {
                /*border-left: 5px solid transparent;*/
                display: none;
                left: 100%;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 0;
                width: 159px;
                z-index: 101;

                li {
                    border-top: 1px solid white;
                    border-left: 3px solid white;

                    &:first-child {
                        border-top: 3px solid white;
                    }

                    &:last-child {
                        border-bottom: 3px solid white;
                    }

                    a {
                        padding: 3px 6px;

                        &:hover {
                            text-decoration: none;
                            background-color: rgba(white, 0.3);
                        }
                    }

                    &.indent {
                        a {
                            padding-left: 12px;
                        }
                    }
                }
            }

            /* on li:hover
            SHOW .sub-nav */
            &:hover {
                .sub-nav {
                    display: block;
                }
            }

            a {
                display: block;
                padding: 1px 0 1px 6px;
                transition: background .4s;
                overflow: hidden;

                svg {

                }

                &:hover {
                    background-color: white;
                        svg {

                        }
                }

            }
        }

        /* Language options */
        .xs-language-options {
            background: rgba(white,0.65);
            border: 1px solid rgba(black,0.5);
            font-size: 0.8em;
            margin: 20px 3px 30px 3px;
            padding: 0.5em 0;

            label {
                margin-bottom: 0;
            }

            a {
                color: #337ab7;
                font-family: initial;
                font-size: 9.6px;
                font-style: normal;
                font-weight: normal;
                line-height: 13.7143px;
                text-align: center;
                text-decoration: underline;

                &::after {
                    content: "|";
                    display: inline-block;
                    margin: 0 0.25em 0 0.5em;
                    text-decoration: none;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }

        } /* /.xs-language-options */

        /* xs-alt-navigation links */
        .xs-alt-navigation {

            li {
                background-color: transparent;
                border: none;
                margin-bottom: 1em;

                a {
                    text-indent: -999em;
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: center;
                    min-height: 31px;
                    width: 129px;

                    /* "Tell a Friend */
                    &[href="/TellaFriend"], &[href="/tellfriend.x"] {
                        background-image: url(/images/tellafriend.png);
                    }
                    
                    /* "Adobe Acrobat */
                    &[href="/DownloadAdobeAcrobat"], &[href="/downloadadobe.x"] {
                        background-image: url(/images/acrobat.png);
                    }
                }
            }
        }/* /.xs-alt-navigation */
    }   
}

