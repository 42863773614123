/* Main Content Styles */
@media (min-width: 0px){
    main {

    }

    /* Specific width of main content to account for sidebar width */
    .xs-main-content {
        display: table-cell;
        float: none;
        vertical-align: top;
    }

    .top-bar {
        position: relative;
        bottom: -2px;
        height: 26px;
        width: 770px;
    }

    .embed-wrapper, iframe {
	    position: relative;
		z-index: 0;
	}
}